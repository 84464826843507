<script>
	import Link from "./Link.svelte";
</script>

<footer
	class="h-32 md:h-20 border-t-surface-600 mt-20 flex items-center lg:mx-40 lg:px-20 flex-col lg:flex-row gap-2 p-5 text-surface-900-50-token"
>
	<div class="text-md lg:flex-1">
		&#169; {new Date().getFullYear()} Nguyen Huu Quoc Thang.
	</div>
	<div class="text-md">
		Built with
		<Link href="https://kit.svelte.dev">SvelteKit</Link>,
		<Link href="https://skeleton.dev">SkeletonUI</Link>, and no thoughts at all.
	</div>
</footer>

<style>
	footer {
		border-top-width: 2px;
	}
</style>
