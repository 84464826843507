<script lang="ts">
	import Header from "$lib/components/Header.svelte";
	import Footer from "$lib/components/Footer.svelte";
	import { siteConfig } from "$lib/config";
	import { X } from "lucide-svelte";
	import "../app.postcss";

	// SkeletonUI stuffs
	import { initializeStores, Drawer, getDrawerStore } from "@skeletonlabs/skeleton";
	import { page } from "$app/stores";
	interface Props {
		children?: import("svelte").Snippet;
	}

	const { children }: Props = $props();

	initializeStores();

	const drawerStore = getDrawerStore();

	const classesActive = $derived((href: string) => {
		return href === $page.url.pathname ? "!variant-soft-secondary" : "";
	});

	const pageMetadata = $derived($page.data.meta);

	const postKeywords = $state(() => {
		const keywords = [];
		if (pageMetadata.postDetails?.keywords?.length) {
			keywords.push(...pageMetadata.postDetails.keywords);
		}
		if (pageMetadata.postDetails?.categories?.length) {
			keywords.push(...pageMetadata.postDetails.categories);
		}
		if (pageMetadata.postDetails?.tags?.length) {
			keywords.push(...pageMetadata.postDetails.tags);
		}

		return keywords.join(", ");
	});

	const postFeaturedImage = $state(() => {
		if (!pageMetadata.postDetails || pageMetadata.postDetails.featuredImage === undefined) {
			return `${siteConfig.baseUrl}/img/site-cover.jpg`;
		}
		return `${siteConfig.baseUrl}${pageMetadata.postDetails.featuredImage}`;
	});
	import { injectSpeedInsights } from "@vercel/speed-insights/sveltekit";

	injectSpeedInsights();
</script>

<svelte:head>
	{#if pageMetadata}
		<meta name="keywords" content={postKeywords()} />

		<meta name="description" content={pageMetadata.description} />
		<meta name="og:description" content={pageMetadata.description} />
		<link rel="canonical" href="{siteConfig.baseUrl}{pageMetadata.postDetails?.slug}" />

		<title
			>{pageMetadata.title}
			|
			{siteConfig.title}</title
		>
		<meta property="og:title" content="{pageMetadata.title} | {siteConfig.title}" />
		<meta property="og:image" content={postFeaturedImage()} />
		<meta property="og:url" content="{siteConfig.baseUrl}{pageMetadata.postDetails?.slug}" />
	{:else}
		<title>{siteConfig.title}</title>
		<meta name="description" content={siteConfig.tagline} />
	{/if}

	<meta property="og:site_name" content="Rookie's Blog" />
	<meta property="og:type" content="website" />
</svelte:head>

<Drawer>
	<nav class="list-nav p-6 w-full">
		<button
			class="btn btn-sm variant-soft-secondary right-0 text-right mb-4"
			onclick={() => drawerStore.close()}
		>
			<X />
		</button>
		<ul>
			{#each siteConfig.routes as route}
				<li class="!mb-1">
					<a href={route.path} class={classesActive(route.path)}>
						<route.sidebarIcon></route.sidebarIcon>
						<span>{route.name}</span>
					</a>
				</li>
			{/each}
		</ul>
		<div class="mt-4 flex gap-3">
			{#each siteConfig.socials as socialLink}
				<a href={socialLink.link} class="btn btn-md">
					<socialLink.icon color="#FFFFFF" size="18" />
				</a>
			{/each}
		</div>
	</nav>
</Drawer>

<main id="main-container" class="mx-auto my-0 p-4 md:p-6 block w-screen lg:max-w-7xl text-xl">
	<Header />
	{@render children?.()}
</main>

<Footer />

<style>
	:global(small) {
		@apply text-gray-500;
	}
</style>
